function singlePropertyMap(config, renderTo) {

    var map = new google.maps.Map(document.getElementById(config.renderTo), {
        center: {lat: config.loadCenter.lat, lng: config.loadCenter.lng},
        scrollwheel: false,
        zoom: config.zoom
    });

    if(config.setBounds){
        var bounds = new google.maps.LatLngBounds();
    }

    if(config.properties){
        config.properties.forEach(function(property){
            var infowindow = new google.maps.InfoWindow({
               content: property.infowindowcontent
            });
            var marker = new google.maps.Marker({
              map: map,
              position: {lat: property.latitude, lng: property.longitude},
              title: property.title,
              icon: property.icon
            });

            marker.addListener('click', function() {
                infowindow.open(map, marker);
            });

            if(config.setBounds){
                bounds.extend({
    				lat:property.latitude,
    				lng:property.longitude
    			});
            }
        });
    }

    if(config.fp_headquarters){
        
        var infowindow = new google.maps.InfoWindow({
           content: config.fp_headquarters.content
        });

        var marker = new google.maps.Marker({
          map: map,
          position: {lat: config.fp_headquarters.lat, lng: config.fp_headquarters.lng},
          title: 'Frankis Porter',
          //icon: "/images/project/eo_hq_icon.png"
        });

        marker.addListener('click', function() {
            infowindow.open(map, marker);
        });
    }

    if(config.setBounds){
        map.fitBounds(bounds);
    }

}
